import { Pipe, PipeTransform, NgModule } from '@angular/core';

@Pipe({
  name: 'myUserfilter',
  pure: false
})

//Filter ROLE_SUPERADMIN!!!!!!!!!
export class MyUserFilterPipe implements PipeTransform {
  transform(items: any[]): any {
    if (!items) {
      return items;
    }
    // filter items array, items which match and return true will be
    return items.filter(item => item.roles.name !== 'ROLE_USER');
  }

  static forRoot() {
    return {
      ngModule: MyUserFilterPipe,
      providers: [],
    };
  }
}