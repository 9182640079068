import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class SensorFileUploadService {
  observeableSensorUploadedFiles: Subject<any> = new Subject<any>()
  rows: number = 0
  totalRows: number = 0

  constructor(
    private apiService: ApiService
  ) {
    this.observeableSensorUploadedFiles.subscribe(async (payload: any) => {
      console.log('in SensorFileUploadService')

      const { fileType } = payload

      // need to handle the varialbel parsedData when its just an array (in-clinic) vs when its an object of L1 and L5 (in-home)

      if (fileType == "In-Clinic") {
        this.uploadInClinicSensor(payload)
      } else if (fileType == "In-Home") {
        this.uploadInHomeSensor(payload)
      } else if (fileType == "Best Trial") {
        
      }
    })
  }

  async uploadInHomeSensor(payload) {
    const { parsedData, filesMetadata, observable } = payload
    let metadata = {}

    console.log("uploadInHomeSensor")

    for (let i = 0; i < filesMetadata.length; i++) {
      if (metadata[filesMetadata[i].location] === undefined) {
        metadata[filesMetadata[i].location] = {}
        metadata[filesMetadata[i].location][filesMetadata[i].fileName] = filesMetadata[i].id
      } else {
        metadata[filesMetadata[i].location][filesMetadata[i].fileName] = filesMetadata[i].id
      }
    }

    for (const key of Object.keys(parsedData)) {
      for (let i = 0; i < parsedData[key].length; i++) {
        this.totalRows = this.totalRows + parsedData[key][i].data.length
      }
    }

    for (const key of Object.keys(parsedData)) {
      let request = []
      for (let i = 0; i < parsedData[key].length; i++) {
        let j = 0
        let data = parsedData[key][i].data
        let sensorFileId = metadata[key][parsedData[key][i].fileName]

        while (j < data.length) {
          console.log(request)
          while (request.length < 10000 && j < data.length) {
            request.push({
              ax: data[j][4],
              ay: data[j][5],
              az: data[j][6],
              day: data[j][0],
              gx: data[j][7],
              gy: data[j][8],
              gz: data[j][9],
              hr: data[j][1],
              min: data[j][2],
              mx: data[j][10],
              my: data[j][11],
              mz: data[j][12],
              sec: data[j][3],
              sensorFileId: sensorFileId
            })

            j++
          }
          const response = await this.apiService.uploadInHomeRows(request).toPromise()
          this.rows = this.rows + request.length
          observable.next({ total: this.totalRows, row: this.rows })
          request = []
        }
      }
    }
  }

  async uploadInClinicSensor(payload) {
    const { parsedData, filesMetadata, observable } = payload
    let metadata = {}

    for (let i = 0; i < parsedData.length; i++) {
      this.totalRows = this.totalRows + parsedData[i].data.length
    }

    for (let i = 0; i < filesMetadata.length; i++) {
      if (metadata[filesMetadata[i].dataType] === undefined) {
        metadata[filesMetadata[i].dataType] = {}
        metadata[filesMetadata[i].dataType][filesMetadata[i].lifewareMacaddr] = filesMetadata[i].id
      } else {
        metadata[filesMetadata[i].dataType][filesMetadata[i].lifewareMacaddr] = filesMetadata[i].id
      }
    }

    console.log(metadata)

    for (let i = 0; i < parsedData.length; i++) {
      let request = []
      if (parsedData[i].fileName.includes("mag")) {
        let j = 0

        while (j < parsedData[i].data.length) {

          while (request.length < 10000 && j < parsedData[i].data.length) {
            request.push({
              x: parsedData[i].data[j][0],
              y: parsedData[i].data[j][1],
              z: parsedData[i].data[j][2],
              configId: parsedData[i].data[j][3],
              sensorId: parsedData[i].data[j][5],
              timeCode: parsedData[i].data[j][6],
              deviceCreateTimestamp: parsedData[i].data[j][7],
              userId: parsedData[i].data[j][9],
              dataType: parsedData[i].data[j][10],
              tag: parsedData[i].data[j][11] ? parsedData[i].data[j][11].split(" ")[0] : null,
              calib: parsedData[i].data[j][12],
              sensorFileId: metadata["mag"][parsedData[i].data[j][4]]
            })

            j++
          }
          const response = await this.apiService.uploadInClinicMagRows(request).toPromise()
          this.rows = this.rows + request.length
          observable.next({ total: this.totalRows, row: this.rows })
          request = []
        }
        
      } else if (parsedData[i].fileName.includes("quat")) {
        let j = 0

        while (j < parsedData[i].data.length) {
          while (request.length < 10000 && j < parsedData[i].data.length) {
            request.push({
              configId: parsedData[i].data[j][4],
              dataType: parsedData[i].data[j][11],
              deviceCreatedTimestamp: parsedData[i].data[j][8],
              sensorId: parsedData[i].data[j][6],
              tag: parsedData[i].data[j][12],
              timeCode: parsedData[i].data[j][7],
              userId: parsedData[i].data[j][10],
              w: parsedData[i].data[j][0],
              x: parsedData[i].data[j][1],
              y: parsedData[i].data[j][2],
              z: parsedData[i].data[j][3],
              sensorFileId: metadata["quat"][parsedData[i].data[j][5]],
            })
            j++
          }
          const response = await this.apiService.uploadInClinicQuatRows(request).toPromise()
          this.rows = this.rows + request.length
          observable.next({ total: this.totalRows, row: this.rows })
          request = []
        }
      } else if (parsedData[i].fileName.includes("accel")) {
        let j = 0

        while (j < parsedData[i].data.length) {
          while (request.length < 10000 && j < parsedData[i].data.length) {
            request.push({
              ay: parsedData[i].data[j][4],
              ayg: parsedData[i].data[j][5],
              az: parsedData[i].data[j][8],
              azg: parsedData[i].data[j][9],
              configId: parsedData[i].data[j][12],
              gy: parsedData[i].data[j][6],
              gydps: parsedData[i].data[j][7],
              gz: parsedData[i].data[j][10],
              gzdps: parsedData[i].data[j][11],
              sensorFileId: metadata["accel"][parsedData[i].data[j][13]],
              sensorId: parsedData[i].data[j][14],
              timeCode: parsedData[i].data[j][15],
            })
            j++
          }
          const response = await this.apiService.uploadInClinicAccelRows(request).toPromise()
          this.rows = this.rows + request.length
          observable.next({ total: this.totalRows, row: this.rows })
          request = []
        }
      }
    }
  }

  getObserveableSensorUploadedFiles() {
    return this.observeableSensorUploadedFiles
  }

  init() {
    return new Promise((resolve, reject) => {
      this.observeableSensorUploadedFiles.subscribe(async (data: any) => {
        console.log('in SensorFileUploadService')
        console.log(data)
      })
      resolve(true)
    })
  }
}
