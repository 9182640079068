import { environment } from "environments/environment";

export class AppConstant {
    public static get API_ENDPOINT(): string {
        if (window.location.host.includes("localhost")) {
            return "http://localhost:8080/api/";
        } else if (window.location.host.includes("lb3p.hari.pitt.edu")) {
            return "https://api.lb3p.hari.pitt.edu/api/";
        } else {
            return "http://localhost:8080/api/";
        }
    }

    public static get RAW_PROCESSED_ENDPOINT(): string {
        if (window.location.host.includes("localhost")) {
            return "http://localhost:8000/";
        } else if (window.location.host.includes("lb3p.hari.pitt.edu")) {
            return "https://api.lb3p.hari.pitt.edu/processing/";
        }
    }
    
    public static VERSION = "3.2.1";
}
 