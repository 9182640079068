import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SidebarComponent } from './sidebar.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatBadgeModule } from '@angular/material'
import { MainPipe } from 'app/main-pipe.module';


@NgModule({
    imports: [RouterModule, CommonModule, NgbModule, MatBadgeModule, MainPipe],
    declarations: [SidebarComponent],
    exports: [SidebarComponent]
})

export class SidebarModule { }
