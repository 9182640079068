import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { JwtResponse } from './jwt-response';
import { TokenStorageService } from './token-storage.service';
import { AuthLoginInfo } from 'app/pages/login/login-info';
import { SignUpInfo } from 'app/user/register/signup-info';
import { User } from 'app/user/userlist/userlist';
import { AppConstant } from './app.constant';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json', observe: 'response' })
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  rootUrl = AppConstant.API_ENDPOINT;

  constructor(private http: HttpClient, private tokenStorage: TokenStorageService) {
  }

  attemptAuth(credentials: AuthLoginInfo) {
    return this.http.post<JwtResponse>(this.rootUrl + 'auth/signin', credentials, httpOptions)
      .pipe(
        map(
          data => {
            console.log("data", data)
            //no role user!
            if(JSON.stringify(data.authorities[0]) == '{"authority":"ROLE_USER"}') {
              return false;
            }
            this.tokenStorage.saveToken(data.accessToken);
            this.tokenStorage.saveParticipantId(data.participantId);
            this.tokenStorage.saveAuthorities(data.authorities);
            data['url'] = this.rootUrl + 'auth/signin';
            return data;
          }
        ));
  }

  signUp(info: SignUpInfo) {
    return this.http.post<string>(this.rootUrl + 'auth/signup', info, httpOptions);
  }
}