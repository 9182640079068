import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyFilterPipe } from './myfilters/myfilterpipe';
import { MyAdminHideFilterPipe } from './myfilters/adminfilterpipe';
import { MyUserFilterPipe } from './myfilters/myuserfilter';
import { SideBarFilterPipe } from './myfilters/sidebarfilter';
import { SideBarScorerFilterPipe } from './myfilters/sidebarscorerfilter';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        MyFilterPipe,
        MyUserFilterPipe,
        MyAdminHideFilterPipe,
        SideBarFilterPipe,
        SideBarScorerFilterPipe],
    exports: [
        MyFilterPipe,
        MyUserFilterPipe,
        MyAdminHideFilterPipe,
        SideBarFilterPipe,
        SideBarScorerFilterPipe
    ]
})
export class MainPipe { }