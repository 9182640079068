import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { HttpModule } from '@angular/http';
import { APP_BASE_HREF } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import * as PlotlyJS from 'plotly.js/dist/plotly.js';
import { AppComponent }   from './app.component';

import { SidebarModule } from './sidebar/sidebar.module';
import { FixedPluginModule } from './shared/fixedplugin/fixedplugin.module';
import { FooterModule } from './shared/footer/footer.module';
import { NavbarModule} from './shared/navbar/navbar.module';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { AppRoutes } from './app.routing';
import { httpInterceptorProviders } from './auth/auth-interceptor';
import { MainPipe } from './main-pipe.module';
import { SensorFileUploadService } from './services/sensor-file-upload.service';

import { ChartModule } from "angular2-chartjs";
import "chartjs-chart-box-and-violin-plot";
@NgModule({
	imports: [
		BrowserAnimationsModule,
        ChartModule,
		FormsModule,
		RouterModule.forRoot(AppRoutes,{
			useHash: true
		}),
		NgbModule.forRoot(),
		HttpModule,
		SidebarModule,
		NavbarModule,
		FooterModule,
		FixedPluginModule, 
		HttpClientModule,
		MainPipe
	],
	declarations: [
		AppComponent,
		AdminLayoutComponent,
		AuthLayoutComponent
	],
	providers: [
		httpInterceptorProviders,
		SensorFileUploadService
		// {
    //   provide: APP_INITIALIZER,
    //   useFactory: (sensorFileUploadService: SensorFileUploadService) => () => sensorFileUploadService.init(),
    //   deps: [SensorFileUploadService],
    //   multi: true
    // },
	],
	bootstrap: [ AppComponent ]
})

export class AppModule { }
