import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'myadminhidefilter',
  pure: false
})
export class MyAdminHideFilterPipe implements PipeTransform {
  transform(items: any[], filter: any): any {

    if (!items || !filter) {
      return items;
    }
    // filter items array, items which match and return true will be
    return items.filter(item => item.participantId.indexOf(filter.participantId) != -1 || item.roles.name !== 'ROLE_SUPERADMIN' || item.roles.name == 'ROLE_ADMIN');
  }
}