import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpEvent, HttpRequest } from '@angular/common/http';
import { TokenStorageService } from 'app/auth/token-storage.service';
import { LogPortal } from 'app/mods/log-portal/logportal'
import { SignUpInfo } from 'app/user/register/signup-info';
import { map, tap } from 'rxjs/operators';
import { DataAccler } from 'app/mods/data-accler/dataaccler';
import { DataGyros } from 'app/mods/data-gyros/datagyros';
import { DataMange } from 'app/mods/data-mange/datamange';
import { DataQuat } from 'app/mods/data-quat/datamange';

import { TestProto } from 'app/mods/data-test-proto/data';
import { UserTest } from 'app/mods/data-test-modul/userForTest';
import { SurveyResults } from 'app/mods/userlist/nav/surveyResult';
import { Observable } from 'rxjs';
import { AppConstant } from 'app/auth/app.constant';
import { ResourceForm } from 'app/mods/data-in-home/data-in-home-resources/resource-add/ResourceForm';

const reqHeader = new HttpHeaders({ 'No-Auth': 'True' });

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json', observe: 'response' })
};
@Injectable({
  providedIn: 'root'
})
export class ApiService {

  rootUrl = AppConstant.API_ENDPOINT;
  RAW_PROCESSED_URL = AppConstant.RAW_PROCESSED_ENDPOINT;

  constructor(private https: HttpClient, private tokenStorage: TokenStorageService) {
  }
  // not used
  getAllUserList() {
    return this.https.get<any[]>(this.rootUrl + "allUsers");
  }
  
  getAvgFailedScreening(startDate, endDate) {
    return this.https.get<any[]>(this.rootUrl + "getAvgFailedScreening" + "/" + startDate + "/" + endDate);
  }

  getAvgNotAttempted(startDate, endDate) {
    return this.https.get<any[]>(this.rootUrl + "getAvgNotAttempted" + "/" + startDate + "/" + endDate);
  }

  // getSensorSynchronizationAttempted(startDate, endDate) {
  //   return this.https.get<any[]>(this.rootUrl + "getSensorSynchronizationAttempted" + "/" + startDate + "/" + endDate);
  // }

  getRecordedTimeSensorSynchronization() {
    return this.https.get<any[]>(this.rootUrl + "getRecordedTimeSensorSynchronization");
  }

  getLeftAxialAttempted(startDate, endDate) {
    return this.https.get<any[]>(this.rootUrl + "getLeftAxialAttempted" + "/" + startDate + "/" + endDate);
  }

  getRightAxialAttempted(startDate, endDate) {
    return this.https.get<any[]>(this.rootUrl + "getRightAxialAttempted" + "/" + startDate + "/" + endDate);
  }

  getLeftLateralBendingAttempted(startDate, endDate) {
    return this.https.get<any[]>(this.rootUrl + "getLeftLateralBendingAttempted" + "/" + startDate + "/" + endDate);
  }

  getRightLateralBendingAttempted(startDate, endDate) {
    return this.https.get<any[]>(this.rootUrl + "getRightLateralBendingAttempted" + "/" + startDate + "/" + endDate);
  }

  getflexionAndExtensionAttempted(startDate, endDate) {
    return this.https.get<any[]>(this.rootUrl + "getflexionAndExtensionAttempted" + "/" + startDate + "/" + endDate);
  }

  getRecordedTimeFlexibilityRomFlexibilityAndExtension() {
    return this.https.get<any[]>(this.rootUrl + "getRecordedTimeFlexibilityRomFlexibilityAndExtension");
  }
  
  // getRecordedTimeSensorSynchronization() {
  //   return this.https.get<any[]>(this.rootUrl + "getRecordedTimeSensorSynchronization");
  // }

  // getSensorSynchronizationAttempted() {
  //   return this.https.get<any[]>(this.rootUrl + "getSensorSynchronizationAttempted");
  // }

  getRecordedTimeFlexibilityRomAxialRotation() {
    return this.https.get<any[]>(this.rootUrl + "getRecordedTimeFlexibilityRomAxialRotation");
  }
  
  getRecordedTimeFlexibilityRomLateralBending() {
    return this.https.get<any[]>(this.rootUrl + "getRecordedTimeFlexibilityRomLateralBending");
  }
  
  getRecordedTimeDynamicMotionAxialRotation() {
    return this.https.get<any[]>(this.rootUrl + "getRecordedTimeDynamicMotionAxialRotation");
  }

  getRecordedTimeDynamicMotionLateralBending() {
    return this.https.get<any[]>(this.rootUrl + "getRecordedTimeDynamicMotionLateralBending");
  }

  getRecordedTimeDynamicMotionFlexion() {
    return this.https.get<any[]>(this.rootUrl + "getRecordedTimeDynamicMotionFlexion");
  }

  getRecordedTimeDynamicMotionCoupledRotationFlexion() {
    return this.https.get<any[]>(this.rootUrl + "getRecordedTimeDynamicMotionCoupledRotationFlexion");
  }
  
  getRecordedTime4MeterWalkTestFirst() {
    return this.https.get<any[]>(this.rootUrl + "getRecordedTime4MeterWalkTestFirst");
  }

  getRecordedTime4MeterWalkTestSecond() {
    return this.https.get<any[]>(this.rootUrl + "getRecordedTime4MeterWalkTestSecond");
  }

  getRecordedTimeFiveTimesToStand() {
    return this.https.get<any[]>(this.rootUrl + "getRecordedTimeFiveTimesToStand");
  }

  getRecordedTimeSideBySideStand() {
    return this.https.get<any[]>(this.rootUrl + "getRecordedTimeSideBySideStand");
  }
  
  getRecordedTimeTandemStand() {
    return this.https.get<any[]>(this.rootUrl + "getRecordedTimeTandemStand");
  }
  
  getRecordedTimeSemiTandemStand() {
    return this.https.get<any[]>(this.rootUrl + "getRecordedTimeSemiTandemStand");
  }

  getSideBySideStandTimeRecorded() {
    return this.https.get<any[]>(this.rootUrl + "getSideBySideStandTimeRecorded");
  }

  getRecordedTimeSingleLegStand() {
    return this.https.get<any[]>(this.rootUrl + "getRecordedTimeSingleLegStand");
  }

  getRecordedTimeTwoMinuteWalk() {
    return this.https.get<any[]>(this.rootUrl + "getRecordedTimeTwoMinuteWalk");
  }

  getRecordedTimeActiveSitUp() {
    return this.https.get<any[]>(this.rootUrl + "getRecordedTimeActiveSitUp");
  }

  getRecordedTimeLumbarQuadrant() {
    return this.https.get<any[]>(this.rootUrl + "getRecordedTimeLumbarQuadrant");
  }

  getRecordedTimeRepeatedMovementExamFlexion() {
    return this.https.get<any[]>(this.rootUrl + "getRecordedTimeRepeatedMovementExamFlexion");
  }

  getRecordedTimeRepeatedMovementExamExtension() {
    return this.https.get<any[]>(this.rootUrl + "getRecordedTimeRepeatedMovementExamExtension");
  }

  getRecordedTimeRepeatedMovementExamSideGliding() {
    return this.https.get<any[]>(this.rootUrl + "getRecordedTimeRepeatedMovementExamSideGliding");
  }

  getRecordedTimeRepeatedExtensionInProne() {
    return this.https.get<any[]>(this.rootUrl + "getRecordedTimeRepeatedExtensionInProne");
  }

  getRecordedTimeRepeatedFlexionInSupine() {
    return this.https.get<any[]>(this.rootUrl + "getRecordedTimeRepeatedFlexionInSupine");
  }

  getScreeningData(startDate, endDate) {
    return this.https.get<any[]>(this.rootUrl + "getScreeningData" + "/" + startDate + "/" + endDate);
  }

  getNonCardioAndCardiogenic(startDate, endDate){
    return this.https.get<any[]>(this.rootUrl + "getNonCardioAndCardiogenic" + "/" + startDate + "/" + endDate)
  }

  getRecordedTimeStairClimbing(){
    return this.https.get<any[]>(this.rootUrl + "getRecordedTimeStairClimbing")
  }
  stairClimbingAttempted(){
    return this.https.get<any[]>(this.rootUrl + "surveyresult/stairClimbingAttempted")
  }

  getUsersByStudySIde(studySite){
    return this.https.get<any[]>(this.rootUrl + "usersByStudySites" + "/" + studySite)
  }

  getInHomeUserCount(startDate, endDate){
    return this.https.get<any[]>(this.rootUrl + "inHomesAns/getUsers" + "/" + startDate + "/" + endDate)
  }

  getInHomeAvgStomp(startDate, endDate){
    return this.https.get<any[]>(this.rootUrl + "inHomesAns/getAvgStomp" + "/" + startDate + "/" + endDate)
  }

  
  getInHomeAvgStompDaily(startDate, endDate){
    return this.https.get<any[]>(this.rootUrl + "inHomesAns/getAvgStompDaily" + "/" + startDate + "/" + endDate)
  }

  getInHomeAvgEMA(startDate, endDate){
    return this.https.get<any[]>(this.rootUrl + "inHomesAns/getAvgEMA" + "/" + startDate + "/" + endDate)
  }

  getInHomeAvgEMADaily(startDate, endDate){
    return this.https.get<any[]>(this.rootUrl + "inHomesAns/getAvgEMADaily" + "/" + startDate + "/" + endDate)
  }

  getAvgStompByCompletionRateRange(startDate, endDate){
    return this.https.get<any[]>(this.rootUrl + "inHomesAns/getAvgStompByCompletionRateRange" + "/" + startDate + "/" + endDate)
  }

  getAvgEMAByCompletionRateRange(startDate, endDate){
    return this.https.get<any[]>(this.rootUrl + "inHomesAns/getAvgEMAByCompletionRateRange" + "/" + startDate + "/" + endDate)
  }

  getScreenedOut(filterDate){
    return this.https.get<any[]>(this.rootUrl + "deviation/screenedout/" + filterDate)
  }

  getNonAttempted(filterDate){
    return this.https.get<any[]>(this.rootUrl + "deviation/nonattempted/" + filterDate)
  }

  getExtremeBPTest(){
    return this.https.get<any[]>(this.rootUrl + "deviation/extremebptest")
  }

  getAllThreeSymptoms(){
    return this.https.get<any[]>(this.rootUrl + "deviation/allthreesymptoms")
  }

  getRaynaudCardiogenic(){
    return this.https.get<any[]>(this.rootUrl + "deviation/raynaudcardiogenic")
  }

  getHighBPRaynaud(){
    return this.https.get<any[]>(this.rootUrl + "deviation/highbpraynaud")
  }

  getHighBPCardiogenic(){
    return this.https.get<any[]>(this.rootUrl + "deviation/highcardiogenic")
  }

  getOnlyHighBP(){
    return this.https.get<any[]>(this.rootUrl + "deviation/onlyhighbp/")
  }

  getOnlyCardiogenic(){
    return this.https.get<any[]>(this.rootUrl + "deviation/onlycardiogenic")
  }

  getOnlyRaynaud(){
    return this.https.get<any[]>(this.rootUrl + "deviation/onlyraynaud")
  }

  getAdminList() {
    return this.https.get<any[]>(this.rootUrl + "adminUsers", {observe: 'response'});
  }

  getVideoScorerList() {
    return this.https.get<any[]>(this.rootUrl + "scorerUsers", {observe: 'response'});
  }
  // not used
  getUserList() {
    return this.https.get<any[]>(this.rootUrl + "users");
  }

  getUserAndArchivedUserList() {
    return this.https.get<any[]>(this.rootUrl + "usersAndArchivedUsers", {observe: 'response'});
  }
  // not used
  getParticipantInVideoAnalysisAssignmentsByScoreId(scoreId){
    return this.https.get<any>(this.rootUrl + scoreId + "/videoAnalysisAssignmentsByScoreId");
  }
  // not used
  getVideoAnalysisAnswers2WithQuestionByPid(pid){
    return this.https.get<any>(this.rootUrl + pid + "/videoAnalysisAnswers2WithQuestion");
  }
  // not used
  getVideoAnalysisAnswersWithQuestionByPid(pid){
    return this.https.get<any>(this.rootUrl + pid + "/videoAnalysisAnswersWithQuestion");
  }

  getVideoAnalysisBothAnswersWithQuestionByPid(pid, scoreUser){
    return this.https.get<any>(this.rootUrl + pid + "/" + scoreUser + "/videoAnalysisBothAnswersWithQuestion");
  }

  getCompletedVideosByScorer(scoreUser){
    return this.https.get<any>(this.rootUrl + scoreUser + "/completedVideos");
  }

  getScoreUsers() {
    return this.https.get<any[]>(this.rootUrl + "scoreUsers", {observe: 'response'});
  }

  getScoreUsersWithProgress() {
    return this.https.get<any[]>(this.rootUrl + "scoreUsersWithProgress", {observe: 'response'});
  }

  getParticipantByScoreUser(pid: any) {
    return this.https.get<any>(this.rootUrl + pid + "/videoAnalysisAssignments", {observe: 'response'});
  }

  createVideoAnalysisAssignment(data) {
    return this.https.post(this.rootUrl + 'videoAnalysisAssignment/create', data, httpOptions);
  }

  getRepeatabilityUsers() {
    return this.https.get<any[]>(this.rootUrl + "repeatabilityUsers", {observe: 'response'});
  }

  getBESTUsers() {
    return this.https.get<any[]>(this.rootUrl + "bestUsers", {observe: 'response'});
  }

  getScorerUsersList() {
    return this.https.get<any[]>(this.rootUrl + "scorerUsers");
  }

  getJustUserList() {
    return this.https.get<any[]>(this.rootUrl + "usersStringList", {observe: 'response'});
  }

  getUserTestList() {
    return this.https.get<any[]>(this.rootUrl + "testusers");
  }

  UsersAndArchivedUsersSensors() {
    return this.https.get<any[]>(this.rootUrl + "usersAndArchivedUsersSensors", {observe: 'response'});
  }

  getUser(id) {
    return this.https.get<any>(this.rootUrl + "user/" + id);
  }

  //update password and....
  updateUser(info: SignUpInfo) {
    return this.https.put<string>(this.rootUrl + 'pUpdate', info, httpOptions);
  }

  updatePassword(pid, info) {
    return this.https.put<string>(this.rootUrl + pid + "/usersStringListupdatePassword", info, httpOptions);
  }

  updatePasswordWithoutChecking(pid, info){
    return this.https.put<string>(this.rootUrl + pid + "/passwordUpdate", info, httpOptions);
  }

  //update user's rule to archived
  updateUserToArchived(Pid) {
    return this.https.put<any>(this.rootUrl + 'archiveUser', Pid, httpOptions);
  }

  updateToRoleUser(Pid) {
    return this.https.put<any>(this.rootUrl + 'unArchiveUser', Pid, httpOptions);
  }

  //update testCode
  updateTUser(info: UserTest) {
    return this.https.put<string>(this.rootUrl + 'testUpdate', info, httpOptions);
  }

  updateTUserWithId(pid, modules) {
    return this.https.put<string>(this.rootUrl + `testUpdate/${pid}`, modules, httpOptions);
  }

  getLogPortals() {
    return this.https.get<LogPortal[]>(this.rootUrl + "logportals", {observe: 'response'});
  }

  getDataAccler() {
    return this.https.get<DataAccler[]>(this.rootUrl + "participants/dataacclers");
  }


  getDataGyros() {
    return this.https.get<DataGyros[]>(this.rootUrl + "participants/datagyross");
  }

  getDataMange() {
    return this.https.get<DataMange[]>(this.rootUrl + "participants/datamanges");
  }

  getDataQuat() {
    return this.https.get<DataQuat[]>(this.rootUrl + "participants/dataquarts");
  }

  getTestresultByPID(pId) {
    return this.https.get<any[]>(this.rootUrl + pId + "/testResult");
  }

  getDeviationByPID(pId) {
    return this.https.get(this.rootUrl + pId + "/deviation", {observe: 'response'});
  }

  getSensorDeviationByPID(pId) {
    return this.https.get(this.rootUrl + pId + "/inHomeDeviationSensors", {observe: 'response'});
  }

  getDeviationsAgg(role) {
    return this.https.get(this.rootUrl + role + "/deviationsAgg", {observe: 'response'});
  }

  getSurveyresultByTestresultID(testId) {
    return this.https.get<SurveyResults[]>(this.rootUrl + testId + "/survey");
  }

  getSurveyresultByTestresultIDFilteredByQuestion(testId) {
    return this.https.get<SurveyResults[]>(this.rootUrl + testId + "/surveyFilteredByQuestion");
  }

  updateSurveyResultByTestresultIDAndCode(answer,id,testId) {
    const params = new HttpParams().set('answer', answer);
    return this.https.put<any>(this.rootUrl + testId + "/" + id + "/survey", {}, { params });
  }

  updateCompletionUpdate(id) {
   
    return this.https.put<any>(this.rootUrl + id + "/completionUpdate", {});
  }

  updateByTestResultsIdAndCode(answer,code,testId) {
    const params = new HttpParams().set('answer', answer);
    return this.https.put<any>(this.rootUrl + testId + "/" + code + "/testResult", {}, { params });
  }

  createVideoLabel(pid, testId, testCode, labelList) {
    return this.https.post(this.rootUrl + pid + "/" + testId + "/" + testCode + "/videoLabelCreate", labelList, httpOptions);
  }

  getVideoLabelByPidTestCode(pId, testCode) {
    return this.https.get<any[]>(this.rootUrl + pId + "/" + testCode + "/getVideoLabel");
  }

  getAnalysisBodyByTestCode(testCode){
    return this.https.get<any[]>(this.rootUrl + "auth/" + testCode + "/getAnalysisBody");
  }

  getLebelOption() {
    return this.https.get<any[]>(this.rootUrl + "labels");
  }

  playVideo(participantId, testId) {
    return this.https.get<boolean>(this.rootUrl + 'check/' + participantId + "/" + testId);
  }

  getTestprotocols() {
    return this.https.get<TestProto[]>(this.rootUrl + "testProtocols", {observe: 'response'});
  }

  updateTestprotocols(info: TestProto[]) {
    return this.https.post(this.rootUrl + 'testProtocols/update', info, httpOptions)
      .pipe(
        tap()
      );
  }

  getInHomeAnsByPID(pId) {
    return this.https.get<any[]>(this.rootUrl + pId + "/inHomesAns", {observe: 'response'});
  }

  getInHomeNotesByPID(pId) {
    return this.https.get(this.rootUrl + pId + "/inHomeNotes", {observe: 'response'});
  }

  getInHomeNotesByRepeatability() {
    return this.https.get(this.rootUrl + "inHomeNotesByRepeat", {observe: 'response'});
  }

  getInHomeNotesByNormalUser() {
    return this.https.get(this.rootUrl + "inHomeNotesByNormalUser", {observe: 'response'});
  }

  getInHomeStompByPID(pId) {
    return this.https.get(this.rootUrl + pId + "/inHomeStomps", {observe: 'response'});
  }

  getInHomeAns() {
    return this.https.get(this.rootUrl + "inHomesAns", {observe: 'response'});
  }

  getInHomeAnsList() {
    return this.https.get(this.rootUrl + "inHomesList");
  }

  getInHomeLog() {
    return this.https.get(this.rootUrl + "inHomeLogs", {observe: 'response'});
  }

  getInHomeResources() {
    return this.https.get(this.rootUrl + "inHomeResources", {observe: 'response'});
  }

  addNewResource(info: ResourceForm) {
    return this.https.post(this.rootUrl + 'inHomeResources/create', info, httpOptions);
  }

  getInClinicLog() {
    return this.https.get(this.rootUrl + "inClinicLogs", {observe: 'response'});
  }

  getApiKey() {
    return this.https.get(this.rootUrl + "apiKeys", {observe: 'response'});
  }

  addNewKey(key, adminUser) {
    let info: any = {
      keyToTest: key,
      adminUser: adminUser
    };
    return this.https.post(this.rootUrl + 'apiKey/create', info, httpOptions);
  }

  addNewLabel(label) {
    let info: any = {
      label: label
    };
    return this.https.post(this.rootUrl + 'label/create', info, httpOptions);
  }

  getEmail() {
    return this.https.get(this.rootUrl + "emails", {observe : 'response'});
  }

  deleteEmailKeyById(id) {
    return this.https.delete(this.rootUrl + id + "/deleteEmail");
  }

  deleteApiKeyById(id) {
    return this.https.delete(this.rootUrl + id + "/deleteAPiKey");
  }

  addEmail(email, adminUser) {
    let info: any = {
      email: email,
      adminUser: adminUser
    };
    return this.https.post(this.rootUrl + 'email/create', info, httpOptions);
  }


  createVideoAnalysisAnswer(body) {
    return this.https.post(this.rootUrl + 'videoAnalysisAnswer/create', body, httpOptions);
  }

  createVideoAnalysisAnswer2(body) {
    return this.https.post(this.rootUrl + 'videoAnalysisAnswer2/create', body, httpOptions);
  }

  getSensors() {
    return this.https.get(this.rootUrl + "datasensors");
  }

  getSensorsByPid(pId) {
    return this.https.get(this.rootUrl + pId + "/datasensor");
  }

  pushFileToStorageL1_V2(file: File, Pid: string) {
    const data: FormData = new FormData();
    data.append('file', file);
    const newRequest = new HttpRequest('POST', this.rootUrl + Pid + '/uploadText/L1', data, {
      reportProgress: true,
      responseType: 'text',
    });
    return this.https.request(newRequest);
  }


  mergeFileToStorageL1_V2(file: File, Pid: string) {
    const data: FormData = new FormData();
    data.append('file', file);
    const newRequest = new HttpRequest('POST', this.rootUrl + Pid + '/sensorFileMerge/L1', data, {
      reportProgress: true,
      responseType: 'text',
    });
    return this.https.request(newRequest);
  }

  pushFileToStorageL5_V2(file: File, Pid: string) {
    const data: FormData = new FormData();
    data.append('file', file);
    const newRequest = new HttpRequest('POST', this.rootUrl + Pid + '/uploadText/L5', data, {
      reportProgress: true,
      responseType: 'text',
    });
    return this.https.request(newRequest);
  }

  pushAFolder(files: Array<File>, type: String) {
    const formData: any = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("uploadingFiles", files[i]);
    }
    const newRequest = new HttpRequest('POST', this.rootUrl + '`auth/uploadFolder/`' + type + "/1", formData, {
      reportProgress: true,
      responseType: 'text',
    });
    return this.https.request(newRequest);
  }

  checkPidInFolder(participantId) {
    return this.https.get<any>(this.rootUrl + 'checkPidInFolder/' + participantId);
  }


  mergeFileToStorageL5_V2(file: File, Pid: string) {
    const data: FormData = new FormData();
    data.append('file', file);
    const newRequest = new HttpRequest('POST', this.rootUrl + Pid + '/sensorFileMerge/L5', data, {
      reportProgress: true,
      responseType: 'text',
    });
    return this.https.request(newRequest);
  }

  getScreenDataByPid(pId) {
    return this.https.get<any>(this.rootUrl + pId + "/datascreen", {observe: 'response'});
  }

  getAllScreenData() {
    return this.https.get<any>(this.rootUrl + "datascreens");
  }

  getDataScreeningByRoles(role) {
    console.log(this.rootUrl + "roles/" + role + "/datascreen")
    return this.https.get<any>(this.rootUrl + "roles/" + role + "/datascreen");
  }

  getAllScreeningQuestions() {
    return this.https.get<any>(this.rootUrl + "datascreenQuesitions");
  }

  getsortData(data) {
    return data.sort((a, b) => {
      return <any>new Date(b) - <any>new Date(a);
    });
  }

  CheckFileExistedInServerL1(pId) {
    return this.https.get<any>(this.rootUrl + pId + "/checkFileExistedInserver/L1");
  }

  CheckFileExistedInServerL5(pId) {
    return this.https.get<any>(this.rootUrl + pId + "/checkFileExistedInserver/L5");
  }

  DeleteL1(pId) {
    return this.https.delete(this.rootUrl + pId + "/sensorFileDelete/L1");
  }

  DeleteL5(pId) {
    return this.https.delete(this.rootUrl + pId + "/sensorFileDelete/L5");
  }

  autoGenerateDeviation(pId) {
    return this.https.get<any>(this.rootUrl + pId + "/generateDeviation", {observe: 'response'});
  }

  // file explor
  getGeneralFileStructure() {
    return this.https.get<any>(this.rootUrl + "fileStructure");
  }

  createFolder(path: string, name: string) {
    if (path === undefined || path == null || path.length == 0) path = "undefined"
    return this.https.get<any>(this.rootUrl + path + "/" + name + "/createFolder");
  }

  deleteFileOrFolder(path: string, name: string) {
    if (path === undefined || path == null || path.length == 0) path = "undefined"
    return this.https.get<any>(this.rootUrl + path + "/" + name + "/deleteFolderOrFile");
  }

  checkFileExisted(path: string, name: string) {
    if (path === undefined || path == null || path.length == 0) path = "undefined"
    return this.https.get<any>(this.rootUrl + path + "/" + name + "/checkFile");
  }

  checkifFIleExistedReturnFileName(path: string, name: string) {
    if (path === undefined || path == null || path.length == 0) path = "undefined"
    return this.https.get<any>(this.rootUrl + path + "/" + name + "/returnFileName");
  }

  pushFile(file: File, path: string, newFileName) {
    if (path === undefined || path == null || path.length == 0) path = "undefined"
    const data: FormData = new FormData();
    data.append('file', file);
    const newRequest = new HttpRequest('POST', this.rootUrl + path + "/" + newFileName + '/uploadFileGeneral', data, {
      reportProgress: true,
      responseType: 'text',
    });
    return this.https.request(newRequest);
  }

  getSensorStructure(pid) {
    return this.https.get<any>(this.rootUrl + pid + "/sensorFileStructure");
  }

  getVideoStructure(pid, directory) {
    return this.https.get<any>(this.rootUrl + pid + "/" + directory +"/videoFileStructure");
  }

  checkVideoFileExisted(pId: string, path: string, name: string) {
    if (path === undefined || path == null || path.length == 0) path = "undefined"
    return this.https.get<any>(this.rootUrl + pId + "/" + path + "/" + name + "/checkVideoFile");
  }


  //researcher
  getFileResearcherStructure() {
    return this.https.get<any>(this.rootUrl + "fileResearcherStructure");
  }

  checkFileResearcherExisted(path: string, name: string) {
    if (path === undefined || path == null || path.length == 0) path = "undefined"
    return this.https.get<any>(this.rootUrl + path + "/" + name + "/checkFileResearcher");
  }

  createFolderResearcher(path: string, name: string) {
    if (path === undefined || path == null || path.length == 0) path = "undefined"
    return this.https.get<any>(this.rootUrl + path + "/" + name + "/createFolderResearcher");
  }

  deleteFileOrFolderResearcher(path: string, name: string) {
    if (path === undefined || path == null || path.length == 0) path = "undefined"
    return this.https.get<any>(this.rootUrl + path + "/" + name + "/deleteFolderOrFileResearcher");
  }

  checkifFIleResearcherExistedReturnFileName(path: string, name: string) {
    if (path === undefined || path == null || path.length == 0) path = "undefined"
    return this.https.get<any>(this.rootUrl + path + "/" + name + "/returnFileResearcherName");
  }

  pushResearcherFile(file: File, path: string, newFileName) {
    if (path === undefined || path == null || path.length == 0) path = "undefined"
    const data: FormData = new FormData();
    data.append('file', file);
    const newRequest = new HttpRequest('POST', this.rootUrl + path + "/" + newFileName + '/uploadFileResearcherGeneral', data, {
      reportProgress: true,
      responseType: 'text',
    });
    return this.https.request(newRequest);
  }

  createLogPortal(logPortal) {
    this.https.post(this.rootUrl + "log-portal/create", logPortal, httpOptions).subscribe()
  }

  getFlagKeywords(){
    return this.https.get<any>(this.rootUrl + "flagKeywords");
  }

  getTestResults(){
    return this.https.get<any>(this.rootUrl + "testResults");
  }

  getTestResultsByProtocol(data, role){
    return this.https.get<any>(this.rootUrl + "test-results/" + data + "/" + role);
  }

  getUserByQuestionCode(questionCode, role){
    return this.https.get<any>(this.rootUrl + "inHomesAns/getUsersByQuestionCode/" + questionCode + "/" + role);
  }

  getSurveyCode(code){
    return this.https.get<any>(this.rootUrl + "survey-code/" + code);
  }

  getAllBestTrialSites(){
    return this.https.get<any>(this.rootUrl + "getUsersStudySite");
  }

  getAllParticipantsBySite(site){
    return this.https.get<any>(`${this.rootUrl}auth/best/participants/` + site);
  }

  getAllBestTrialSitesWithPasscodes() {
    return this.https.get<any>(`${this.rootUrl}auth/best/sites/passwords`);
  }

  createStudySite(body) {
    return this.https.post(this.rootUrl + "best/site/create", body, httpOptions)
  }

  createPasscode(body) {
    return this.https.post(this.rootUrl + "auth/best/passcode/create", body, httpOptions)
  }

  getAllPasscodes() {
    return this.https.get<any>(`${this.rootUrl}auth/best/passcode`);
  }

  updatePasscode(body) {
    return this.https.put<any>(`${this.rootUrl}auth/best/passcode/update`, body, httpOptions)
  }

  deletePasscode(params) {
    return this.https.delete(`${this.rootUrl}auth/best/passcode/delete`, { params })
  }

  deleteSite(id) {
    return this.https.delete(`${this.rootUrl}best/site/delete/${id}`)
  }

  deletePasscodesBySite(params) {
    return this.https.delete(`${this.rootUrl}auth/best/passcode/deleteSite`, { params })
  }

  updateSite(body) {
    return this.https.put<any>(`${this.rootUrl}auth/best/site/update`, body, httpOptions)
  }

  registerBestParticipantWithSession(session, originalId, body) {
    return this.https.post<any>(`${this.rootUrl}best/registerwithsession/${session}/${originalId}`, body, httpOptions)
  }

  insertSensorFiles(body) {
    return this.https.post<any>(`${this.rootUrl}sensor-file`, body, httpOptions)
  }

  uploadInClinicMagRows(body) {
    return this.https.post<any>(`${this.rootUrl}in-clinic-mag-upload`, body, httpOptions)
  }
  
  uploadInClinicQuatRows(body) {
    return this.https.post<any>(`${this.rootUrl}in-clinic-quat-upload`, body, httpOptions)
  }

  uploadInClinicAccelRows(body) {
    return this.https.post<any>(`${this.rootUrl}in-clinic-accel-upload`, body, httpOptions)
  }

  uploadInHomeRows(body) {
    return this.https.post<any>(`${this.rootUrl}in-home-upload`, body, httpOptions)
  }

  sensorFileUpload(files, participantId, type, adminId) {
    const formData = new FormData();

    formData.append("participantId", participantId)
    formData.append("type", type)
    formData.append("adminId", adminId)

    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }

    // const newRequest = new HttpRequest('POST', `${this.rootUrl}sensor-file/upload`, formData, {
    //   reportProgress: true,
    //   responseType: 'text',
    // });

    // return this.https.request(newRequest);

    // return this.https.post<any>(`${this.rootUrl}sensor-file/upload`, formData);

    const newRequest = new HttpRequest('POST', `${this.RAW_PROCESSED_URL}upload-files`, formData, {
      reportProgress: true,
      responseType: 'text',
    });

    return this.https.request(newRequest);
  }

  uploadProcessedDataV2(file, adminId) {
    const formData = new FormData();

    formData.append("file", file);
    formData.append("adminId", adminId);

    const request = new HttpRequest('POST', `${this.RAW_PROCESSED_URL}v2/process`, formData)

    return this.https.request(request)
  }

  uploadProccesedData(file, participantId, fileType, location) {
    const formData = new FormData();

    formData.append("participantId", participantId)
    formData.append("type", fileType)
    formData.append("file", file);
    formData.append("location", location);
    
    const newRequest = new HttpRequest('POST', `${this.rootUrl}sensor-file/processed/upload`, formData, {
      reportProgress: true,
      responseType: 'text',
    });

    return this.https.request(newRequest);
  }

  getFilesToBeProcessed(adminId) {
    return this.https.get<any>(`${this.rootUrl}sensor-file/file-in-queue/${adminId}`);
  }

  getUserByParticipantId(participantId) {
    return this.https.get<any>(`${this.rootUrl}user/participant-id/${participantId}`);
  }

  getProcessedSensorFile(type, pid) {
    return this.https.get<any>(`${this.rootUrl}sensor-file/processed/${type}/${pid}`);
  }

  getSensorSynchronizationAttempt() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/sensorsynchronizationattempt")
  }

  getAxialRotationLeftAttempt() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/axialrotationleftattempt")
  }

  getAxialrotationRightAttempt() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/axialrotationrightattempt")
  }

  getLateralBendingLeftAttempt() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/lateralbendingleftattempt")
  }

  getLateralBendingRightAttempt() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/lateralbendingrightattempt")
  }
  
  getFlexionExtensionAttempt() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/flexionextensionattempt")
  }

  getFlexionExtension() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/flexionextension")
  }

  getFlexionMeasurement() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/flexionmeasurement")
  }

  getExtensionMeasurement() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/extensionmeasurement")
  }

  getDynamicMotionLeftAxialRotationAttempt() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/dynamicmotionleftaxialrotationattempt")
  }

  getDynamicMotionRightAxialRotationAttempt() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/dynamicmotionrightaxialrotationattempt")
  }

  getDynamicMotionLeftLateralBendingAttempt() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/dynamicmotionleftlateralbendingattempt")
  }

  getDynamicMotionRightLateralBendingAttempt() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/dynamicmotionrightlateralbendingattempt")
  }

  getDynamicMotionLeftCoupledRotationFlexion() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/dynamicmotionleftcoupledrotationflexion")
  }

  getDynamicMotionRightCoupledRotationFlexion() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/dynamicmotionrightcoupledrotationflexion")
  }

  getFourMeterWalkFirstTest() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/fourmeterwalkfirsttest")
  }

  getFourMeterWalkSecondTest() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/fourmeterwalksecondtest")
  }

  getFourMeterWalkAids() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/fourmeterwalkaids")
  }

  getFiveTimesSitToStandWithoutHelp() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/fivetimessittostandwithouthelp")
  }

  getFiveTimesSitToStandTestAttempt() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/fivetimessittostandtestattempt")
  }

  getFiveTimesSitToStandTestComplete() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/fivetimessittostandtestcomplete")
  }

  getSideBySideStandTestAttempt() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/sidebysidestandtestattempt")
  }

  getSideBySideStandScore() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/sidebysidestandscore")
  }

  getSemiTandemStandTestAttempt() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/semitandemstandtestattempt")
  }

  getSemiTandemStandScore() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/semitandemstandscore")
  }

  getTandemStandTestAttempt() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/tandemstandtestattempt")
  }

  getTandemStandScore() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/tandemstandscore")
  }

  getSingleLegStandTestAttempt() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/singlelegstandtestattempt")
  }

  getSingleLegStandScore() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/singlelegstandscore")
  }

  getLiftNumberCount() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/liftnumbercount")
  }

  getLowBackPainRating() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/lowbackpainrating")
  }

  getTwoMinuteWalkTest() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/twominutewalktest")
  }

  getTwoMinuteWalkTestAids() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/twominutewalktestaids")
  }

  getSpineNeutralScore() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/spineneutralscore")
  }

  getFlexionDominanceScore() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/flexiondominancescore")
  }

  getBaseOfSupportScore() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/baseofsupportscore")
  }

  getAberrantMovementScore() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/aberrantmovementscore")
  }

  getBoxProximityToBodyScore() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/boxproximitytobodyscore")
  }

  getPosturalLiftingStrategyTotalScore() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/posturalliftingstrategytotalscore")
  }

  getPosturalLiftingStrategyAttempt() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/posturalliftingstrategyattempt")
  }

  getPosturalLiftingStrategyTestStopTime() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/posturalliftingstrategyteststoptime")
  }

  getActiveSitUpTestAttempt() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/activesituptestattempt")
  }

  getActiveSitUpTestGetIntoPosition() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/activesituptestgetintoposition")
  }

  getActiveSitUpTestBackPain() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/activesituptestbackpain")
  }

  getLumbarQuadrantLeftTestAttempt() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/lumbarquadrantlefttestattempt")
  }

  getLumbarQuadrantLeftTestSymptom() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/lumbarquadrantlefttestsymptom")
  }

  getLumbarQuadrantRightTestAttempt() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/lumbarquadrantrighttestattempt")
  }

  getLumbarQuadrantRightTestSymptom() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/lumbarquadrantrighttestsymptom")
  }

  getRepeatedMovementExamFlexionAttempt() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/repeatedmovementexamflexionattempt")
  }

  getRepeatedMovementExamFlexionPainChange() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/repeatedmovementexamflexionpainchange")
  }

  getRepeatedMovementExamFlexionOccurence() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/repeatedmovementexamflexionoccurence")
  }

  getRepeatedMovementExamFlexionAberrantMotion() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/repeatedmovementexamflexionaberrantmotion")
  }

  getRepeatedMovementExamFlexionAberrantMotionQ8() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/repeatedmovementexamflexionaberrantmotionq8")
  }

  getRepeatedMovementExamFlexionAberrantMotionQ6() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/repeatedmovementexamflexionaberrantmotionq6")
  }

  getRepeatedMovementExamFlexionAberrantMotionQ4() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/repeatedmovementexamflexionaberrantmotionq4")
  }

  getRepeatedMovementExamFlexionAberrantMotionQ2() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/repeatedmovementexamflexionaberrantmotionq2")
  }

  getRepeatedMovementExamExtensionAttempt() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/repeatedmovementexamextensionattempt")
  }

  getRepeatedMovementExamExtensionPainChange() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/repeatedmovementexamextensionpainchange")
  }

  getRepeatedMovementExamExtensionOccurence() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/repeatedmovementexamextensionoccurence")
  }

  getRepeatedMovementExamSideGlidingAttempt() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/repeatedmovementexamsideglidingattempt")
  }

  getRepeatedMovementExamLeftSideGlidingAttempt() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/repeatedmovementexamleftsideglidingattempt")
  }

  getRepeatedMovementExamLeftSideGlidingPainChange() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/repeatedmovementexamleftsideglidingpainchange")
  }

  getRepeatedMovementExamLeftSideGlidingOccurence() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/repeatedmovementexamleftsideglidingoccurence")
  }

  getRepeatedMovementExamRightSideGlidingDone() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/repeatedmovementexamrightsideglidingdone")
  }

  getRepeatedMovementExamRightSideGlidingPainChange() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/repeatedmovementexamrightsideglidingpainchange")
  }

  getRepeatedMovementExamRightSideGlidingOccurence() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/repeatedmovementexamrightsideglidingoccurence")
  }

  getRepeatedExtensionInProneIndication() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/repeatedextensioninproneindication")
  }

  getRepeatedExtensionInProneAttempt() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/repeatedextensioninproneattempt")
  }

  getRepeatedExtensionInPronePainChange() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/repeatedextensioninpronepainchange")
  }

  getRepeatedExtensionInProneOccurence() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/repeatedextensioninproneoccurence")
  }

  getRepeatedExtensionInProneLeftDone() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/repeatedextensioninproneleftdone")
  }

  getRepeatedExtensionInProneRightDone() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/repeatedextensioninpronerightdone")
  }

  getRepeatedExtensionInSupineIndication() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/repeatedextensioninsupineindication")
  }

  getRepeatedExtensionInSupineAttempt() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/repeatedextensioninsupineattempt")
  }

  getRepeatedExtensionInSupinePainChange() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/repeatedextensioninsupinepainchange")
  }

  getRepeatedExtensionInSupineOccurence() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/repeatedextensioninsupineoccurence")
  }

  getPainAssessmentEndPainRating() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/painassessmentendpainrating")
  }

  getPainAssessmenBeginningPainRating() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/painassessmentbeginningpainrating")
  }

  getPainAssessmenBeginningType() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/painassessmentbeginningtype")
  }

  getPainDifference() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/paindifference")
  }

  getGeneralReportUserInformation() {
    return this.https.get<any[]>(this.rootUrl + "general-report/user/info")
  }

  getFiveTimesToSitStandTime() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/fiveTimesToSitStandTime")
  }

  getFiveTimesToSitStandTimeHistogram() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/fiveTimesToSitStandTimeHistogram")
  }

  getSingleLegStandTime() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/singleLegStandTime")
  }
  getSideBySideTime() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/sideBySideTime")
  }
  getSemiTandemTime() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/semiTandemTime")
  }
  getTandemTime() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/tandemTime")
  }
  getFourMeterWalkFirstTime() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/fourMeterWalkFirstTime")
  }
  getFourMeterWalkSecondTime() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/fourMeterWalkSecondTime")
  }
  getTwoMinuteWalkTime() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/twoMinuteWalkTime")
  }

  getSingleLegStandTimeHistogram() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/singleLegStandTimeHistogram")
  }
  getSideBySideTimeHistogram() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/sideBySideTimeHistogram")
  }
  getSemiTandemTimeHistogram() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/semiTandemTimeHistogram")
  }
  getTandemTimeHistogram() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/tandemTimeHistogram")
  }
  getFourMeterWalkFirstTimeHistogram() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/fourMeterWalkFirstTimeHistogram")
  }
  getFourMeterWalkSecondTimeHistogram() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/fourMeterWalkSecondTimeHistogram")
  }
  getTwoMinuteWalkTimeHistogram() {
    return this.https.get<any[]>(this.rootUrl + "surveyresult/twoMinuteWalkTimeHistogram")
  }
  
  status() {
    return this.https.get<any[]>(this.rootUrl + "v2/status")
  }

  // this.headers = new HttpHeaders({
  //   'Content-Type': 'application/json',
  //   'token': token,
  //   'admin-token': '123'
  // })

  getUsers(queryParams) {
    return this.https.get<Object>(this.rootUrl + `v2/users?${queryParams}`)
  }

  getProccessedDataV2(participantId) {
    return this.https.get<Object>(this.rootUrl + `v2/processed-data?participantId=${participantId}`)
  }
}
