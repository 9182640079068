import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { TokenStorageService } from '../auth/token-storage.service';


@Injectable({
  providedIn: 'root'
})


//canot explicity access other pages！！！！！！
export class RouteGuardService implements CanActivate {

  constructor(
    private tokenStorageService: TokenStorageService,
    private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (this.tokenStorageService.isUserLoggedIn() != null){
      return true;
    } //check token from session storage

    this.router.navigate(['']);

    return false;//we return false to roter.ts
  } 

}
