import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpEvent, HttpRequest } from '@angular/common/http';
import * as io from "socket.io-client";
// npm install --save socketio socket.io-client
import { observable, Subscriber, Observable } from 'rxjs';
import { AppConstant } from 'app/auth/app.constant';
import { EventService } from './event.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json', observe: 'response' })
};


const httpOptionsForNotification = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization : 'key=' + "AAAAVdTKlM0:APA91bEMiMvuVDhDtszU9HKEOrUg32A0FQTZHIorsvNvCNG6CfmnF26HFPKOYPzVajvO65Y1G48um0ZlG4H8bCQp0yjXAHtgc8nCMC_b6XSomb36Ty7sBWCCTpvVEIaruAX9GeGiWIr6"
    })
};

const headers: HttpHeaders = new HttpHeaders({
  'Content-Type': 'application/json',
  Authorization: 'key=' + "AAAAVdTKlM0:APA91bEMiMvuVDhDtszU9HKEOrUg32A0FQTZHIorsvNvCNG6CfmnF26HFPKOYPzVajvO65Y1G48um0ZlG4H8bCQp0yjXAHtgc8nCMC_b6XSomb36Ty7sBWCCTpvVEIaruAX9GeGiWIr6"
});


@Injectable({
  providedIn: 'root'
})
export class ChatService {

  //this is for maintaing the number of unread message!
  public mySet = new Set();
  private socket: SocketIOClient.Socket;
  rootUrl = AppConstant.API_ENDPOINT;
  constructor(private https: HttpClient, public eventService: EventService) {
    // this.socket = io('http://localhost:3000');
    //please be noted that socket.io should be added because that is a thing requred in server!!!
    //the first half is for hostName! so add path on second half!!
    //The page at '<URL>' was loaded over HTTPS, but requested an insecure image '<URL>'. This content should also be served over HTTPS.
    //using https!

    this.socket = io.connect('https://shrsft6029himb.shrs.pitt.edu', { path: '/lb3p/dev/socket/socket.io' });
    // this.socket = io.connect('http://chat.lb3p.hari.pitt.edu', { path: '/socket.io' });
  }

  getChatByRoom(room) {
    return this.https.get(this.rootUrl + "chat/" + room);
  }


  getChat() {
    return this.https.get(this.rootUrl + "chats", {observe: 'response'});
  }

  getAllChatDesc() {
    return this.https.get(this.rootUrl + "chatsDesc", {observe: 'response'});
  }

  saveChat(data) {
    return this.https.post(this.rootUrl + "chat", data, httpOptions);
  }

  updateChatToRead(data: any) {
    return this.https.post<string>(this.rootUrl + "chatToUpdate", data, httpOptions);
  }

  getCountOfUnreadChat(taker) {
    return this.https.get<any[]>(this.rootUrl + taker + "/unreadChats");
  }

  listen(eventName: string) {
    return new Observable((Subscriber) => {
      this.socket.on(eventName, (data) => {
        Subscriber.next(data);
      })
    })
  }

  emit(eventName: string, data: any) {
    this.socket.emit(eventName, data);
  }



  getSizeFromSet() {
    return this.mySet.size;
  }

  hasExistedInSet(element: string) {
    return this.mySet.has(element);
  }

  addElementInSet(element: string) {
    this.mySet.add(element);
    this.eventService.event.emit('detectChanged', "changes")
  }

  deleteElementInSet(element: string) {
    this.mySet.delete(element);
  }

  // resetSet(){
  //   this.mySet =  new Set();
  // }

  retrieveSet() {
    return this.mySet;
  }

  // getChatByRoom(room) {
  //   return new Promise((resolve, reject) => {
  //     this.http.get('http://localhost:3000/chat/' + room)
  //       .pipe(map(res => res.json()))
  //       .subscribe(res => {
  //         resolve(res);
  //       }, (err) => {
  //         reject(err);
  //       });
  //   });  
  // }

  // showChat(id) {
  //   return new Promise((resolve, reject) => {
  //       this.http.get('http://localhost:3000/chat/' + id)
  //       .pipe(map(res => res.json()))
  //         .subscribe(res => {
  //           resolve(res)
  //       }, (err) => {
  //         reject(err);
  //       });
  //   });
  // }

  // saveChat(data) {
  //   return new Promise((resolve, reject) => {
  //       this.http.post('http://localhost:3000/chat', data)
  //       .pipe(map(res => res.json()))
  //         .subscribe(res => {
  //           resolve(res);
  //         }, (err) => {
  //           reject(err);
  //         });
  //   });
  // }

  // updateChat(id, data) {
  //   return new Promise((resolve, reject) => {
  //       this.http.put('http://localhost:3000/chat/'+id, data)
  //       .pipe(map(res => res.json()))
  //         .subscribe(res => {
  //           resolve(res);
  //         }, (err) => {
  //           reject(err);
  //         });
  //   });
  // }

  // deleteChat(id) {
  //   return new Promise((resolve, reject) => {
  //       this.http.delete('http://localhost:3000/chat/'+id)
  //         .subscribe(res => {
  //           resolve(res);
  //         }, (err) => {
  //           reject(err);
  //         });
  //   });
  // }

  // private handleError(error: any): Promise<any> {
  //   return Promise.reject(error.message || error);
  // }

}
