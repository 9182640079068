import { Routes } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { RouteGuardService } from './services/route-guard.service';

export const AppRoutes: Routes = [
    {
        path: '',
        redirectTo: 'pages',
        pathMatch: 'full',
    },
    {
        path: '',
        component: AuthLayoutComponent,
        children: [{
            path: 'pages',
            loadChildren: './pages/pages.module#PagesModule'
        }]
    },
    { 
        path: '',
        component: AdminLayoutComponent,
        children: [{ 
            path: 'mods',
            canActivate: [RouteGuardService],
            loadChildren: './mods/mods.module#ModsModule'
        },  
        {
            path: 'user',
            canActivate: [RouteGuardService],
            loadChildren: './user/user.module#UserModule'
        }]
    }
];
