import { Injectable } from '@angular/core';
import { Subscription, Subject, Observable } from 'rxjs';

const TOKEN_KEY = 'AuthToken';
const PARTICIPANTID_KEY = 'AuthParticipant';
const AUTHORITIES_KEY = 'AuthAuthorities';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {

  private role: string;
  constructor() { }

  signOut() {
    localStorage.clear();
  }

  public saveToken(token: string) {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.setItem(TOKEN_KEY, token);
  }

  public saveParticipantId(participantId: string) {
    localStorage.removeItem(PARTICIPANTID_KEY);
    localStorage.setItem(PARTICIPANTID_KEY, participantId);
  }

  public saveAuthorities(authorities: string) {
    localStorage.removeItem(AUTHORITIES_KEY);
    localStorage.setItem(AUTHORITIES_KEY, JSON.stringify(authorities));
  }

  public getToken(): string {
    return localStorage.getItem(TOKEN_KEY);
  }

  public getParticipantId(): string {
    return localStorage.getItem(PARTICIPANTID_KEY);
  }

  public getAuthorities() {
    let temp = []
    this.role = "";
    if (localStorage.getItem(TOKEN_KEY)) {
      temp = JSON.parse(localStorage.getItem(AUTHORITIES_KEY));
      this.role = temp[0].authority;
    }
    return this.role;
  }

  isUserLoggedIn() {
    let user = localStorage.getItem(PARTICIPANTID_KEY)
    return !(user === null)
  }

}
