import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sidebarScorerFilter',
  pure: false
})
export class SideBarScorerFilterPipe implements PipeTransform {
  transform(items: any[], filter: any[]): any {

    if (!items || !filter) {
      return items;
    }

    if (filter[1] == 'ROLE_VIDEO_SCORER') return items.filter(item => item.title.indexOf(filter[0]) != -1);
    else return items;

  }
}