import { Component, OnInit, AfterViewInit, AfterViewChecked, AfterContentInit } from '@angular/core';
import { AppConstant } from 'app/auth/app.constant';
import { AuthService } from 'app/auth/auth.service';
import { TokenStorageService } from 'app/auth/token-storage.service';
import { ChatService } from 'app/services/chat.service';
import { EventService } from 'app/services/event.service';

//Metadata
export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    collapse?: string;
    icontype: string;
    // icon: string;
    children?: ChildrenItems[];
}

export interface ChildrenItems {
    children?: ChildrenItems[]; //??
    path: string;
    title: string;
    ab: string;
    type?: string;
}

//Menu Items
export const ROUTES: RouteInfo[] = [
    {
        path: '/mods',
        title: 'LB3P',
        type: 'sub',
        icontype: 'nc-icon nc-bank',
        children: [
            { path: 'data-dashboard', title: 'Dashboard', ab: 'D' },
            { path: 'userlist-inclinic-perform-test', title: 'Performance Test Report', ab: 'PT' },
            { path: 'general-report', title: 'General Report', ab: 'PT' },
            { path: 'userlist_d', title: 'Main', ab: 'M' },
            { path: 'userlist_inhome_d', title: 'In-Home Data', ab: 'IH' },
            { path: 'notesummary_d', title: 'Notes List', ab: 'NS' },
            { path: 'test-result-report-d', title: 'Test Result Report', ab: 'TR' },
            { path: 'deviation_d', title: 'Deviation Report', ab: 'DR' },
            { path: 'inHomesSensors_d', title: 'Sensors', ab: 'S' },
            { path: 'inhome-report_d', title: 'In-Home Report', ab: 'IHR' },
            // { path: 'userlist-inhome-new-perform-test', title: 'New Performance Test Report', ab: 'PT' },
        ]
    },
    { 
        path: '/mods',
        title: 'Repeatability',
        type: 'sub',
        icontype: 'nc-icon nc-bank',
        children: [
            { path: 'userlist_r', title: 'Main', ab: 'M' },
            { path: 'userlist_inhome_r', title: 'In-Home Data', ab: 'IH' },
            { path: 'notesummary_r', title: 'Notes List', ab: 'NS' },
            { path: 'test-result-report-r', title: 'Test Result Report', ab: 'TR' },
            { path: 'deviation_r', title: 'Deviation Report', ab: 'DR' },
            { path: 'inHomesSensors_r', title: 'Sensors', ab: 'S' },
            { path: 'inhome-report_r', title: 'In-Home Report', ab: 'IHR' }
        ]
    },
    { 
        path: '/mods',
        title: 'Best_Trial',
        type: 'sub',
        icontype: 'nc-icon nc-bank',
        children: [
            { path: 'userlist_b', title: 'Main', ab: 'M' },
            { path: 'best-trial-participant-registration', title: 'Participant Registration', ab: 'PR' },
            { path: 'study-site', title: 'Study Site', ab: 'SS' },
            { path: 'passcode', title: 'Passcode', ab: 'P' }
        ]
    },
    { 
        path: '/user',
        title: 'Accounts',
        type: 'sub',
        icontype: 'nc-icon nc-badge',
        children: [
            { path: 'adminList', title: 'Admin', ab: 'A' },
            { path: 'scorerList', title: 'Video Scorer', ab: 'VS' },
            { path: 'userlist_d', title: 'Participant', ab: 'P' },
            { path: 'userlist_r', title: 'Participant [R]', ab: 'PR' },
            { path: 'userlist_b', title: 'Participant [BEST]', ab: 'PB' },
            { path: 'register', title: 'Registration', ab: 'R' },
            { path: 'passwordUpdate', title: 'Password', ab: 'P' }
        ]
    },
    {
        path: '/mods',
        title: 'Sensor_Data',
        type: 'sub',
        icontype: 'nc-icon nc-spaceship',
        children: [
            { path: 'sensor', title: 'Sensor Data', ab: 'SD' },
            { path: 'folderUpload', title: 'Upload Raw Data', ab: 'RD' },
            { path: 'upload-processed-data', title: 'Upload Processed Data', ab: 'PD' },
        ]
    },
    {
        path: '/mods',
        title: 'Messaging',
        type: 'sub',
        icontype: 'nc-icon nc-send',
        children: [
            { path: 'chatroom', title: 'Messages', ab: 'CR' },
            { path: 'chatdata', title: 'Messages List', ab: 'CD' },
        ]
    },
    {
        path: '/mods',
        title: 'Files',
        type: 'sub',
        icontype: 'nc-icon nc-paper',
        children: [
            { path: 'fileSystem', title: 'General Folder', ab: 'GF' },
            { path: 'fileResearcher', title: 'Researcher Folder', ab: 'RF' },
        ]
    },
    {
        path: '/mods',
        title: 'Video_Analysis',
        type: 'sub',
        icontype: 'nc-icon nc-tag-content',
        children: [
            { path: 'videoAnalysis', title: 'Video Scoring', ab: 'VS' },
            // { path: 'videoAnalysisDashboard', title: 'Dashboard', ab: 'D' },
            { path: 'videoAnalysisScoringData', title: 'Video Analysis Dashboard', ab: 'D' },
            { path: 'videoAnalysisAssignment', title: 'Video Assignments', ab: 'VA' },
        ]
    },
    {
        path: '/mods',
        title: 'Video_Labeler',
        type: 'sub',
        icontype: 'nc-icon nc-tag-content',
        children: [
            { path: 'videoLabeler', title: 'Video Labeler', ab: 'VL' },
            { path: 'completionMarker', title: 'Completion Marker', ab: 'CM' }
        ]
    },
    {
        path: '/mods',
        title: "Other",
        type: 'sub',
        icontype: 'nc-icon nc-zoom-split',
        children: [
            { path: 'logportal', title: 'Portal Log', ab: 'A' },
            { path: 'proto', title: 'Test Proto', ab: 'B' },
            { path: 'module', title: 'Test Module', ab: 'C' },
            { path: 'inHomeAns', title: 'In Home Ans', ab: 'D' },
            { path: 'inHomeLog', title: 'In Home Log', ab: 'G' },
            { path: 'inHomeResources', title: 'In Home Resource', ab: 'Z' },
            { path: 'inClinicLog', title: 'In Clinic Log', ab: 'H' },
            { path: 'apiKey', title: 'APi Mangement', ab: 'C' },
            { path: 'emailList', title: 'Email Mangement', ab: 'E' },
            // { path: 'l1FolderExample', title: 'L1 Folder Upload ', ab: 'x' },
            // {path: 'jsondEdit', title: 'Json Edit', ab: 'C'},
            // {path: 'accler', title: 'Accelerometer', ab: 'C'},
            // {path: 'gyros', title: 'Gyroscope', ab: 'D'},
            // {path: 'mange', title: 'Magnetometer', ab: 'E'},
            // {path: 'quat', title: 'Quaternion', ab: 'G'},
        ]
    }
];

@Component({
    moduleId: module.id,
    selector: 'sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})

export class SidebarComponent {

    public unreadCount: any = 0;
    public mySet;
    public updateChild: any;
    public menuItems: any[];
    public hasRole: boolean = false;
    public username: string;
    public temp: any[];
    public role:string;
    public versionNumber = AppConstant.VERSION;

    isNotMobileMenu() {
        if (window.outerWidth > 991) {
            return false;
        }
        return true;
    }

    constructor(private tokenStorage: TokenStorageService,
        private authService: AuthService, private chatService: ChatService, public eventService: EventService) {
        let taker = "Admin";
        this.chatService.getCountOfUnreadChat(taker).subscribe((res) => {
            this.temp = res;
            this.temp.forEach(element => {
                if (!this.chatService.hasExistedInSet(element)) {
                    this.chatService.addElementInSet(element)
                }
            })
            this.unreadCount = res.length;
        })
    }

    ngOnInit() {
        if (this.tokenStorage.getToken()) {
            this.username = this.tokenStorage.getParticipantId();
            this.role = this.tokenStorage.getAuthorities();
            if (this.role === 'ROLE_SUPERADMIN') {
                this.hasRole = true;
            } else {
                this.hasRole = false;
            }
        }

        console.log(this.role)

        this.menuItems = ROUTES.filter(menuItem => menuItem);


        this.chatService.listen('new-message').subscribe((data: any) => {
            //this "superadmin" shoud be cur user!
            let taker = "Admin";

            var usr = JSON.parse(localStorage.getItem("user"));
            if (usr) { //if if are in the room (getting usr)
                let curUser = usr.name
                let curTalk = usr.talkto;
                //data in this case usr.talkto;
                //data usr.name
                //meaning that ur in the room and talking, so markd the new message as readed
                if (curUser == data.message.talkto && curTalk == data.message.name) {
                    let newData: any = {
                        giver: data.message.talkto,
                        taker: data.message.name // this will be admin or superadmin
                    }

                    this.chatService.updateChatToRead(newData).subscribe((res) => {
                        console.log(res);
                    })
                } else {// if we are not in cur room we need to get the count of un read!
                    this.chatService.getCountOfUnreadChat(taker).subscribe((res) => {
                        this.temp = res;
                        this.temp.forEach(element => {
                            if (!this.chatService.hasExistedInSet(element)) {
                                this.chatService.addElementInSet(element)
                            }
                        })
                        this.unreadCount = res.length;
                    })
                }
            } else {
                //no user meaning that we nore not in the room!!!
                if (data.message.name !== "Admin")
                    this.chatService.getCountOfUnreadChat(taker).subscribe((res) => {
                        this.temp = res;
                        this.temp.forEach(element => {
                            if (!this.chatService.hasExistedInSet(element)) {
                                this.chatService.addElementInSet(element)
                            }
                        })
                        this.unreadCount = res.length;
                    })
            }

        })

        //be noted that this is trigger from useListitem
        this.eventService.event.on('talk', (talkTo: any) => {
            //we just need to grab the totla count
            //i don't think wee ne to hand the count when we talk
            let taker = "Admin"; // not talk to
            if (this.chatService.hasExistedInSet(talkTo)) {
                this.chatService.deleteElementInSet(talkTo)
            }
            this.chatService.getCountOfUnreadChat(taker).subscribe((res) => {
                this.temp = res;
                this.temp.forEach(element => {
                    if (!this.chatService.hasExistedInSet(element)) {
                        this.chatService.addElementInSet(element)
                    }
                })
                this.unreadCount = res.length;
            })
        })

    }

    ngAfterViewInit() { }
}



//in portal -> name:admin -> talkTo:222
//in app -> name:222 -> TalkTo:admin

//message from portal, we mark name:admin -> talkTo:222 -> received:false
//message from app we, mark name:222 -> TalkTo:admin -> received:false

//when we in the room we pull the chat that satisify the condition above

//in the portal, we check talkTo:admin(this is because those are message sent from app) in database, and see how many unreadmessage group by name except for supadmin

//when we talk(enter in the room) we mark all unreadmessage as read