import { Pipe, PipeTransform, NgModule } from '@angular/core';

@Pipe({
  name: 'myfilter',
  pure: false
})

//Filter ROLE_SUPERADMIN!!!!!!!!!
export class MyFilterPipe implements PipeTransform {
  transform(items: any[]): any {
    //checek the null
    if (!items || !items[0]) {
      return items;
    }

    if (items[0][1] == null) {
      // this is for Participant & Admin List 
      return items.filter(item => item.roles.name === 'ROLE_USER');
    } else {
      // this is for BACPAC Dashboard -> due to the difference of json format -> check like this //see res
      // items[0][1] is number of completed tests for BACPAC Dashboard, which is null for Participant & Admin List 
      return items.filter(item => item[0].roles.name === 'ROLE_USER');
    }
  }

  static forRoot() {
    return {
      ngModule: MyFilterPipe,
      providers: [],
    };
  }
}
