import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sidebarFilter',
  pure: false
})
export class SideBarFilterPipe implements PipeTransform {
  transform(items: any[], filter: any[]): any {

    if (!items || !filter) {
      return items;
    }
    // filter items array, items which match and return true will be
    let role = filter[0];
    // console.log(role);
    // console.log(items);
    if(role != "ROLE_SUPERADMIN"){
      items = items.filter(item => item.title != "Admin");
    }
    if(role == "ROLE_VIDEO_SCORER"){
      items = items.filter(item => item.path == "videoAnalysis")
    } else if (role == "ROLE_BEST_ADMIN" || role == "ROLE_CBS_ADMIN" ) {
      items = items.filter(item => item.path == "userlist_b")
    } else {
      items = items.filter(item => item.path != "videoAnalysis");
    }
    // items = items.filter(item => item.path != "videoAnalysis");
    return items;
  }

}

 